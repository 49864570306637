@use "styles/colours";

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated-button {
  border-radius: 8px;
  // animation for fade-in bg to avoid flickering
  background-color: rgba(colours.$yellow, 1);
  transition: background-color 1s ease;
  box-shadow: inset 0 0 0 10px colours.$lightestGrey;
  position: relative;

  // pseudo elements are fix for 0.5px height render issue
  // between html div and react-native view
  // (bad calculation between px and pt)
  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: colours.$white;
    border-radius: 50%;
    top: -1px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: -1px;
    background-color: colours.$white;
    border-radius: 50%;
  }

  &__text {
    opacity: 1;
    transition: none;

    &--animated {
      position: absolute;
      left: 40px;
      z-index: -1;

      & > * {
        transition: none;
        color: rgba(colours.$lightestGrey, 0);
      }
    }

    // animation for slow fade in of default text
    &.hide {
      animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
    }
  }

  &--animated {
    background-color: rgba(colours.$lightestGrey, 1);
    // animation to fade-out bg colour
    transition: background-color 1s ease 1s;
    box-shadow: none;

    .animated-button__text {
      opacity: 0;

      &--animated {
        // animation to fade-out text
        transition: opacity 0.5s ease-out 1s;

        & > * {
          color: colours.$black;
        }
      }
    }
  }
}
