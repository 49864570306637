@use "styles/colours";
@use "styles/typography";

.dropdown-menu-container {
  display: flex;
  transition: none;
}

.dropdown-menu {
  max-height: 300px;
  overflow: auto;

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 4px;
    border: 2px solid colours.$lightGrey;
    padding: 10px 12px;
    cursor: pointer;
    width: 100%;
  }

  &__button-error {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 4px;
    border: 2px solid colours.$red;
    padding: 10px 12px;
    cursor: pointer;
    width: 100%;
  }

  &__item-label {
    font-family:
  "-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif";
    font-weight: "600";
    line-height: "24px";
    font-size: "14px";
    letter-spacing: "0.25px";
    color: colours.$darkGrey;
  }
}

// These need to be imported after "@szhsin/react-menu/dist/index.css"; to override the dropdown styles
.szh-menu-container {
  transition: none;
}

.szh-menu {
  transition: none;
}

.szh-menu__item--hover {
  background-color: colours.$starburst;
}

.szh-menu__item--open {
  background-color: colours.$starburst;
}