@use "styles/colours";
@use "styles/breakpoints";
@use "styles/typography";
@use "styles/vars";

.restaurants {
  @include vars.mainBackground;
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  flex-direction: column;
  &__store-search {
    display: flex;
    flex: 1;
    max-width: 739px;
  }
  &__store-search-toggle {
    align-self: center;
  }
  &__store-filter {
    overflow-x: auto;
    max-width: 762px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &-container {
      width: 100%;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &__map {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    * {
      transition: none;
    } 

    & > div:first-of-type {
      display: flex;
      flex-grow: 1;
      position: relative;
    }

    #MY_LOCATION_BUTTON_WRAPPER {
      position: static !important;
      top: 0 !important;
    }

    .myLocationButton {
      padding: 4px;
      background-color: colours.$white;
      margin: 10px;
      position: absolute;
      right: 0;
      bottom: 100px;
      box-shadow: rgba(colours.$black, 0.3) 0px 1px 4px -1px;
      cursor: pointer;

      img {
        filter: invert(35%);
        width: 32px;
        height: 32px;
      }
    }

    &-marker {
      width: 44px;
      height: 48px;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease;

      img {
        width: 100%;
        height: 100%;
      }

      &--selected {
        width: 60px;
        height: 68px;
      }
    }

    &-popup {
      position: absolute;
      width: 100%;
      height: 196px;
      bottom: -1000px;
      opacity: 0;
      transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
      z-index: 1;

      @media (min-width: breakpoints.$md) {
        height: 100%;
        width: 326px;
        bottom: 0;
        left: -1000px;
      }

      &.show {
        opacity: 1;
        bottom: -8px;
        height: auto;

        @media (min-width: breakpoints.$md) {
          height: 100%;
          bottom: 0;
          left: -8px;
        }
      }

      .card-sticker {
        background: colours.$white;
        height: 100%;
        width: 100%;
        padding-bottom: 8px;

        @media (min-width: breakpoints.$md) {
          padding-bottom: 0;
          padding-left: 8px;
        }
      }
    }
  }
  &__list {
    &-container {
      max-width: 762px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      flex: 1;
    }
    &-view {
      border-left: 1px solid lightgrey;
      border-right: 1px solid lightgrey;
      border-top: 1px solid lightgrey;
    }
    &-wrapper {
      padding-top: 0;
      padding-bottom: 40px;
    }
  }
  &__order-btn {
    width: 156px;
    padding: 16px;
    @media (max-width: breakpoints.$md) {
      width: auto;
      flex: 1;
    }
  }

  &__details {
    min-width: 100vw;
    max-height: 100vh;
    height: auto;
    overflow: auto;

    @media (min-width: breakpoints.$md) {
      max-width: 760px;
      min-width: 760px;
      min-height: auto;
      width: auto;
      height: auto;
    }

    &-row2 {
      display: flex;
      flex-direction: column;
      border-bottom: solid #e0e0e0;
      border-bottom-width: 1px;

      @media (min-width: breakpoints.$md) {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &-col {
      padding: 16px;
      padding-top: 0;
      @media (min-width: breakpoints.$md) {
        width: 50%;
      }
    }

    &-buttonsRow {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &-header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      & > div:first-of-type {
        padding: 16px 16px 0 16px;
      }

      [data-testid="icon-button"] {
        margin-top: 5px;
      }
    }

    &-headline {
      @extend .headline;
      margin-bottom: 8px;
      margin-top: 24px;
    }

    &-headline2 {
      @extend .body-bold;
      margin-bottom: 8px;
    }

    &-restaurantName {
      @extend .large-title;
      margin-bottom: 8px;
    }

    &-map {
      margin-top: 0px;
      min-width: 320px;
    }

    &-button {
      width: 50%;
      margin: 16px 0;

      &:first-of-type {
        margin-right: 16px;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 16px;
      position: relative;
      flex-direction: column;
      border-top: 1px solid colours.$lightGrey;
      @media (min-width: breakpoints.$md) {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
}

.store-search {
  &__container {
    flex: 1;
    z-index: 2;
    position: relative;
  }
  &__store-result {
    cursor: pointer;
    &:hover {
      background-color: colours.$starburst30;
    }

    &__detail {
      flex-direction: row;
      margin-left: 16px;
      justify-content: center;
      flex: 1;
      @media (min-width: breakpoints.$md) {
        display: flex;
      }
      &--left {
        flex: 0.6;
      }
      &--right {
        flex: 0.4;
      }
    }
    &__address {
      @media (max-width: breakpoints.$md) {
        margin-top: 11px;
      }
    }
  }
  &__search-result {
    width: 100%;
    max-height: 50vh;
    position: absolute;
    z-index: 2;
    overflow: auto;
    background-color: colours.$white;
    border-radius: 8px;
    @include vars.tripleBoxShadow;

    @media (min-width: breakpoints.$md) {
      max-height: 400px;
    }
  }
}
