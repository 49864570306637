@use "styles/colours";
@use "styles/breakpoints";
@use "styles/typography";
@use "styles/shared";

.order-setup {
  display: flex;
  flex-direction: column;
  min-width: 750px;
  max-width: 750px;
  min-height: 720px;
  height: auto;
  padding-bottom: 87px;
  overflow: auto;
  max-height: 720px;
  box-sizing: border-box;
  padding: 0;

  @media screen and (max-height: 720px) {
      min-height:auto
    }

  @media (max-width: breakpoints.$md) {
    background: #fff;
    width: 95vw;
    height: 85vh;
    padding: 0;
    min-width: auto;
    min-height: auto;
    max-height: none;
  }

  &__button {
    width: 100%;
    flex-direction: row;
    display: flex;

    &-wrapper {
      @media (min-width: 830px) {
        flex: 1;
        margin: 16px 24px;
      }
    }
  }

  &__error-message {
    @extend .error-style-caption;
    margin-top: 4px;
    font-size: 12px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    margin: 24px;
    justify-content: space-between;
    align-items: center;
  }

  &__main_title {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    @extend .title-one;
    @media (min-device-width:320px) and (max-device-width : 480px) {
    font-size: 22px;
    }
  }

  &__body {
    flex: 1;
    margin: 16px 24px 24px 24px;
    overflow-x: hidden;
  }

  &__invisible {
    opacity: 0;
  }
}

#order-setup-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

#StoreDetailsCard {
  @media (max-width: breakpoints.$md) {
    padding-bottom: 70px;
  }
}

#SelectStoreButtonWrapper {
  @media (max-width: breakpoints.$md) {
    position: absolute;
    bottom: -60px;
    width: 100%;
    max-width: 100%;
    flex: 1;
  }
}

.delivery-info-container {
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
}


ul#autocomplete-results {
  border-radius: 8px;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  li {
    padding: 16px 24px;
    font-size: 14px;
    font-family:
  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.horizontal-radio-button-list {
  height: 36px;
  width: 100%;
  display: flex;
  flex: 1;
  margin-bottom: 24px;
  input {
    position: absolute;
    left: -999999px;
  }
  label {
    background: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-family:
  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    font-weight: 600;
    color: #646464;
    line-height: 1;
    font-size: 13px;
    letter-spacing: 0.25px;
    cursor: pointer;
    &:first-of-type {
      border-radius: 18px 0 0 18px;
    }
    &:last-of-type {
      border-radius: 0 18px 18px 0;
    }
  }
  input:checked + label {
    background: #ffd204;
    color: #171717;
  }
}

.order-setup__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
}

.order-setup__body > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
